import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_STANDARD_PROPOSAL_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalColumnDefinition";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const CopyToStdProposalTablePanel:React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const gridRef: any = useRef(null);
    const {copyToStandardProposalList} = standardProposalState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_COL_DEF.slice());
        
        if (!standardProposalState.selectCopyToStdProposalRows ||
            standardProposalState.selectCopyToStdProposalRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateCopyToSelectedRows(selectedRows);
      }, [standardProposalVM])

      const handClose = useCallback(() => {
        standardProposalVM.onTariffItemCopyCloseClick();
    }, [standardProposalVM]);

      const memoBtns = useMemo(()=>{

        const hdrBtns = [];
        
        hdrBtns.push(
            {
                id: 'onCloseButton',
                icon: 'Icon-cross',
                title: 'Close'
            }
        )


        return hdrBtns
    },[]) 

    const memoStandardProposalTable = useMemo(() => {

        return (
        <HPHTable
            id='standard-proposal-table'
            isNewColumnSetting={true}
            columns={INITIAL_STANDARD_PROPOSAL_COL_DEF.slice()}
            data={transferRowData(copyToStandardProposalList??[])}
            showPaginator={false}
            headerActionButtons={memoBtns}
            onCloseButton={handClose}
            editable={false}
            showUploadIcon={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 200px)" 
            ref={gridRef}
        />
        );
    },[copyToStandardProposalList, memoBtns, handClose, handleSelectionChange])

    return <><TableWrapper>
            {memoStandardProposalTable}
        </TableWrapper>
        </>;
}

export default memo(CopyToStdProposalTablePanel);
