import { TariffNatureEntity } from "domain/entity/TariffNature/TariffNatureEntity";
import _ from "lodash";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffNatureRepository } from "./TariffNatureRepo";

export const TariffNatureRepoImpl = (): TariffNatureRepository => {
    const url = '/v1/tariffNatureForComboBox';

    const getAllActiveTariffNature = async (): Promise<TariffNatureEntity[]> => {
        return axiosGetData(tariffAxiosInstance, url, []).then(res => {
            return _.sortBy(res.data, ["tariffNature"]);
        }).catch(err => {
            return [];
        });
    }


    return {
        getAllActiveTariffNature: getAllActiveTariffNature,
    }
}