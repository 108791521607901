import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useCallback, useMemo } from "react";
import { HPHButton, SliderPanel } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import StandardProposalTariffItemTablePanel from "./StandardProposalTariffItemTablePanel";
import TariffItemTierTablePanel from "./TariffItemTierTablePanel";

const TariffItemRightPanel = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const messageBarVM = useMessageBarVM();
    const {deleteItemList,isShowTariffItemTierPanel,searchCriteria,searchStatus,tariffCodeIds,isUpdateOrAdd,currentTariffItem,isAddIs,isAddTier,isEditIs,isEditTier,isAddTariffItem } = standardProposalState;
    
    const memoTariffItemTierTablePanel = useMemo(() => {
        return <TariffItemTierTablePanel/>
    },[])

    const memoTariffItemTable = useMemo(() => {
        return <StandardProposalTariffItemTablePanel/>
    },[])

    // const isDisplay = () => {
    //     if(isAddIs||isAddTier||isEditIs||isEditTier||isEditStdProposal||isAddTariffItem||isUpdateOrAdd) return true
    //     return false;
    // }
    const isDisable = () => {
        if(!(isAddIs||isAddTier||isEditIs||isEditTier)&&(isAddTariffItem||isUpdateOrAdd)) return false
        return true;
    }
    
    // const recordValidate = useCallback(() =>{
    //     if(!standardProposalState.currentSelectedRow.effectiveDate){
    //         messageBarVM.showWarining("effective Date is mandatory.");
    //         return false;
    //     }
    //     if(!standardProposalState.currentSelectedRow.expiryDate){
    //         messageBarVM.showWarining("expiry Date is mandatory.");
    //         return false;
            
    //     }
    //     if(!standardProposalState.currentSelectedRow.currencyCode){
    //         messageBarVM.showWarining("currency is mandatory.");
    //         return false;
    //     }
    //     return true;
    // },[messageBarVM, standardProposalState.currentSelectedRow.currencyCode, standardProposalState.currentSelectedRow.effectiveDate, standardProposalState.currentSelectedRow.expiryDate])

    const handleSave = useCallback(async () => {
        if(isAddTariffItem){
            if(!currentTariffItem.tariffType){
                messageBarVM.showWarining("Tariff Type is mandatory.");
                return;
            }
            if(!currentTariffItem.tariffCode){
                messageBarVM.showWarining("Tariff Code is mandatory.");
                return;
            }
        }
        if(standardProposalState.standardProposalItemList && standardProposalState.standardProposalItemList.length > 0){
            if (!currentTariffItem.key && standardProposalState.standardProposalItemList.some(item => 
                item.tariffType === currentTariffItem.tariffType &&
                item.tariffCode === currentTariffItem.tariffCode &&
                (item.forwarderCode?item.forwarderCode:"") === currentTariffItem.forwarderCode
              )) {
                messageBarVM.showWarining("Duplicate record found, please check the key: Tariff Type, Tariff Code, Forwarder Code.");
                return;
              }
        }
        if(isUpdateOrAdd){
            if(currentTariffItem.tierList && currentTariffItem.tierList.length === 0){
                messageBarVM.showWarining("Must add one tariff item tier at least.");
                return;
            }
            standardProposalVM.onShowLoading();
            await standardProposalVM.onSaveTariffItem(standardProposalState.currentSelectedRow,currentTariffItem,tariffCodeIds,deleteItemList).then(async (data) => {
                if(data.code !== "200"){
                    messageBarVM.showError(data.data);
                    standardProposalVM.onHideLoading();
                    return;
                }else{
                    if(searchStatus !== ""){
                        await standardProposalVM.onGroupButtonClick(searchStatus);
                    }else{
                        await standardProposalVM.searchStandardProposal(searchCriteria);
                    }
                }
            });
        }
        standardProposalVM.onHideLoading();
        
    },[isAddTariffItem, standardProposalState.standardProposalItemList, standardProposalState.currentSelectedRow, isUpdateOrAdd, standardProposalVM, currentTariffItem, messageBarVM, tariffCodeIds, deleteItemList, searchStatus, searchCriteria]);


    return <>
        <div className={`main-comp-wrapper${(isShowTariffItemTierPanel) ? '' : ' im-hide-side-form-draggable'}`}>
            <div style={{width: '100%', height: '100%', maxHeight: '85%'}}>          
                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={isShowTariffItemTierPanel?"50%":"100%"}
                    rightSectionWidth={isShowTariffItemTierPanel?"50%":"0%"}
                    leftChildren={memoTariffItemTable}
                    rightChildren={memoTariffItemTierTablePanel}
                />
            </div>
            
            <div style={{paddingRight: '5px',visibility:!isDisable()?'visible':'hidden'}} >
            <SidebarActionBar>
                {<HPHButton disabled={isDisable()} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />}
                {/* {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />} */}
            </SidebarActionBar>
            </div>
        </div>
    </>
}

export default memo(TariffItemRightPanel);