import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalTariffItemTierColumnDefinition";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, HPHTable } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CopyToStdProposalTablePanel from "./CopyToStdProposalTablePanel";
import TariffItemHeaderBar from "./TariffItemHeaderBar";
import TariffItemISTablePanel from "./TariffItemISTablePanel";
import TariffItemIsEditPanel from "./TariffItemIsEditPanel";


const TariffItemTierTablePanel: React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const { tariffCodeIds,searchStatus,searchCriteria,selectCopyToStdProposalRows,isShowCopyPanel,currentTariffItem, currentTariffItemTier,isAddIs,isAddTier,isEditIs,isEditTier,tariffItemTierSelectedRows } = standardProposalState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedTariffItemTierRows(selectedRows);
    }, [standardProposalVM])


    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice());
        
        // if (!standardProposalState.tariffItemSelectedRows ||
        //     standardProposalState.tariffItemSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })

    const handleAdd = useCallback(() => {
        if("FINALIZED" === standardProposalState.currentSelectedRow.status || "PROPOSED" === standardProposalState.currentSelectedRow.status){
            messageBarVM.showWarining("Standard Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot Add.");
            return;
        }
        standardProposalVM.onAddTier();
    }, [messageBarVM, standardProposalState.currentSelectedRow.status, standardProposalVM]);

    const handClose = useCallback(() => {
        standardProposalVM.onTariffItemTierCloseClick();
    }, [standardProposalVM]);


    const handEdit = useCallback(() => {
        if("FINALIZED" === standardProposalState.currentSelectedRow.status || "PROPOSED" === standardProposalState.currentSelectedRow.status){
            messageBarVM.showWarining("Standard Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot be edited.");
            return;
        }
        if(currentTariffItemTier?.cycle == null){
            messageBarVM.showWarining("Please select a Tier record.");
            return;
        }
        standardProposalVM.onTariffItemTierEidtClick();
    }, [currentTariffItemTier?.cycle, messageBarVM, standardProposalState.currentSelectedRow.status, standardProposalVM]);

    const handDelete = useCallback(() => {
        if(tariffItemTierSelectedRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        if(currentTariffItem.tierList && (currentTariffItem.tierList.length === 1 || currentTariffItem.tierList.length === tariffItemTierSelectedRows.length)){
            messageBarVM.showWarining("At least one tier is required.");
            return;
        }
        standardProposalVM.onTariffItemTierDeleteClick(tariffItemTierSelectedRows);
    }, [currentTariffItem.tierList, messageBarVM, standardProposalVM, tariffItemTierSelectedRows]);

    const memoBtns = useMemo(()=>{

        const hdrBtns = [];
        if(!(isAddTier||isEditTier||isAddIs||isEditIs)){
            hdrBtns.push({                
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
            hdrBtns.push({                
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }
        
        hdrBtns.push(
            {
                id: 'onCloseButton',
                icon: 'Icon-cross',
                title: 'Close'
            }
        )

        return hdrBtns
    },[isAddIs, isAddTier, isEditIs, isEditTier]) 

    const memoStandardProposalTariffItemTierTable = useMemo(() => {

        return (
            <HPHTable
                id='standard-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice()}
                data={transferRowData(currentTariffItem.tierList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handEdit}
                onDeleteButton={handDelete}
                onCloseButton={handClose}
                showPaginator={false}
                editable={false}
                showUploadIcon={false}
                showAddIcon={!(isAddTier||isEditTier||isAddIs||isEditIs)}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 780px)"
                ref={gridRef}
            />
        );
    }, [currentTariffItem.tierList, handClose, handDelete, handEdit, handleAdd, handleSelectionChange, isAddIs, isAddTier, isEditIs, isEditTier, memoBtns])

    const memoTariffItemHeaderTable = useMemo(() => {
        return <TariffItemHeaderBar/>
    },[])

    const memoTariffItemISTable = useMemo(() => {
        return <TariffItemISTablePanel/>
    },[])

    const memoTariffItemIsEditPanel = useMemo(() => {
        return <TariffItemIsEditPanel/>
    },[])

    const memoCopyTariffItemPanel = useMemo(() => {
        return <CopyToStdProposalTablePanel/>
    },[])

    const isCopyDisable = () => {
        if(selectCopyToStdProposalRows.length > 0) return false
        return true
    }

    const handleCopy = useCallback(() => {
        standardProposalVM.onShowLoading();
        standardProposalVM.onTariffItemCopy(currentTariffItem,tariffCodeIds,selectCopyToStdProposalRows).then(async (data) =>{
            if(data.code !== "200"){
                messageBarVM.showWarining(data.data);
                standardProposalVM.onHideLoading();
                return;
            }else{
                if(searchStatus !== ""){
                    await standardProposalVM.onGroupButtonClick(searchStatus);
                }else{
                    await standardProposalVM.searchStandardProposal(searchCriteria);
                }
                standardProposalVM.onHideLoading();
            }
        });
    }, [currentTariffItem, messageBarVM, searchCriteria, searchStatus, selectCopyToStdProposalRows, standardProposalVM, tariffCodeIds]);
    return <>
    <div className="main-comp-wrapper">
        {isShowCopyPanel?
            <div style={{width: '100%', height: '100%', maxHeight: '85%'}}>
                {memoCopyTariffItemPanel}
                {
                <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center", justifyContent: "flex-end"}}>
                <StyledAction className="tm-animated-wrapper">
                {<HPHButton disabled={isCopyDisable()} label={WorkspaceConstant.Common.BUTTON_COPY} size={'Small'} theme={'Primary'} onClick={handleCopy} />}
                </StyledAction>
                </Sidebarheader>
                }
            </div>
        :
        <div style={{width: '100%', height: '100%', maxHeight: '85%'}}>
        {memoTariffItemHeaderTable}
            <TableWrapper>
            {memoStandardProposalTariffItemTierTable}
            </TableWrapper>
            {memoTariffItemISTable}
            {<div className={`child-div${(isAddIs||isAddTier||isEditIs||isEditTier)? " child-div-std-tariff-item-edit-expanded " : ""}`}>
                {memoTariffItemIsEditPanel}
            </div>}
        </div>}
    </div>
    </>;
}

export default memo(TariffItemTierTablePanel);
