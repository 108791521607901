import { StandardProposalConstant, standardProposalTariffItemRequiredFieldList } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useEffect, useMemo } from "react";
import { CommonField, FieldType, IFieldValue } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffItemHeaderBar:React.FC = () => {
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const [standardProposalState] = useStandardProposalTracked();
    const { currentTariffItem,isAddTariffItem,currentSelectedRow,isShowTariffItemTierPanel } = standardProposalState;
    const { isSaveClicked} = standardProposalState.stdProposalState;
    const standardProposalVM = useStandardProposalVM();

    // const memoTariffCodeOptions = useMemo(() => {  
    //     return currentTariffItem.tariffType  
    //         ? standardProposalState.dynamicOptions.tariffCodeDropdownOptions[currentTariffItem.tariffType]  
    //         : [];  
    // }, [standardProposalState.dynamicOptions.tariffCodeDropdownOptions, currentTariffItem.tariffType]);

    useEffect(() => {
        const refreshTariffCode = async()=>{
            console.log("refreshTariffCode");
            standardProposalVM.initComboBoxTariffCode(currentSelectedRow,currentTariffItem.tariffType??"");
        }    
        if(currentTariffItem.tariffType){
            isShowTariffItemTierPanel&&refreshTariffCode();
        }
            
    },[isShowTariffItemTierPanel,currentSelectedRow, currentTariffItem.tariffType, standardProposalVM])


    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"5px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={!isAddTariffItem&&currentTariffItem.key!==""}
                isShowOptional={true}
                readOnlyValue={currentTariffItem?.tariffType || ''}
                fieldValue={currentTariffItem?.tariffType}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={standardProposalTariffItemRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onTariffItemFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE, currentTariffItem.key, currentTariffItem?.tariffType, isAddTariffItem, isSaveClicked, standardProposalState.dynamicOptions.tariffTypeDropdownOptions, standardProposalVM])

    const memotariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"5px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={!isAddTariffItem&&currentTariffItem.key!==""}
                isShowOptional={true}
                readOnlyValue={currentTariffItem?.tariffCode || ''}
                fieldValue={currentTariffItem?.tariffCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.tariffCodeDropdownOptions}
                requiredFieldList={standardProposalTariffItemRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onTariffItemFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.TARIFF_CODE, currentTariffItem.key, currentTariffItem?.tariffCode, isAddTariffItem, isSaveClicked, standardProposalState.dynamicOptions.tariffCodeDropdownOptions, standardProposalVM])


    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom: "5px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={!isAddTariffItem&&currentTariffItem.key!==""}
                isShowOptional={true}
                readOnlyValue={currentTariffItem?.forwarderCode || ''}
                fieldValue={currentTariffItem?.forwarderCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.FORWARDER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'forwarderCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.forwarderCodeDropdownOptions}
                requiredFieldList={standardProposalTariffItemRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalVM.onTariffItemFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.FORWARDER_CODE, currentTariffItem?.forwarderCode, currentTariffItem.key, isAddTariffItem, isSaveClicked, standardProposalState.dynamicOptions.forwarderCodeDropdownOptions, standardProposalVM])



    return <Sidebarheader style={{width: '100%'}}>
        <CriteriaItemContainer>
            {memoTariffType}
            {memotariffCode}
            {memoForwarderCode}
        </CriteriaItemContainer>
    </Sidebarheader>
}

export default memo(TariffItemHeaderBar);