import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useMemo } from "react";
import { CommonField, DialogModal, FieldType, HPHButton, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";

const StandardProposalRejectReasonModal: React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();

    const {searchCriteria,searchStatus,currentSelectedRow, isShowRejectPad} = standardProposalState;
    
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Title;


    const onRejectClicked = () => {  
        standardProposalVM.onReject(searchCriteria,searchStatus,currentSelectedRow);
    }

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"395px",marginBottom:"24px"}} >
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow.rejectReason || ''}
                fieldValue={currentSelectedRow.rejectReason}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.REJECT_REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'rejectReason'}
                maxLength={200}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => standardProposalVM.onRejectReasonTextAreaChange(fieldValue && fieldValue.toString())} 
                requiredFieldList={['rejectReason']}                    />
        </div>
    , [STANDARD_PROPOSAL_CONSTANT.REJECT_REASON, currentSelectedRow.rejectReason, standardProposalVM])
    


    return <><DialogModal style={{  minWidth:'650px', minHeight:'650px' }}
    appendTo='self'
    showButton={false}
    visible={isShowRejectPad}
    positions="center"
    resizable={false}
    header={
        <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">Reject Reason</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={standardProposalVM.cancelRejectReasonModal} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                    {memoRejectReason}
                </CriteriaItemContainer> 

            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton disabled={currentSelectedRow.rejectReason?.length===0} label={'Reject'} size={'Small'} theme={'Primary'} onClick={onRejectClicked}/>
            </div>
        }
    /></>;
};

export default memo(StandardProposalRejectReasonModal);