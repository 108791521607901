import { StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const StandardProposalTitleBar:React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const {isShowCopyPanel,isShowTariffItemTierPanel,isUpdateOrAdd,isEditStdProposal,selectedRows,searchCriteria,searchStatus} = standardProposalState;
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Title;
    const messageBarVM = useMessageBarVM();
    const [anaInfoState] = useANAInfoTracked();
    const {allowUpdate} = anaInfoState;
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);
    const [ deletedProposal, setDeletedProposal ] = useState<StandardProposalEntity[]>();
    // const allowReadRej = isPermissionExist(Permission.STANDARD_PROPOSAL_REJECT, AclType.READ, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    // const allowUpdateRej = isPermissionExist(Permission.STANDARD_PROPOSAL_REJECT, AclType.UPDATE, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    // const allowReadApp = isPermissionExist(Permission.STANDARD_PROPOSAL_APPROVE, AclType.READ, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    // const allowUpdateApp = isPermissionExist(Permission.STANDARD_PROPOSAL_APPROVE, AclType.UPDATE, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    
    const handleSearch = useCallback(() => {
        standardProposalVM.onSearchClick();
    }, [standardProposalVM]);

    const handleAdd = useCallback(() => {
        standardProposalVM.onAddClick();
    }, [standardProposalVM]);
    
    const handleReject = useCallback(async () => {
        if(selectedRows.length !== 1){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        if(!selectedRows.every(entity => entity.status === "FINALIZED")){
            messageBarVM.showWarining("The selected proposal is not in \"FINALIZED\" state.");
            return;
        }
        standardProposalVM.onRejectClick();

    }, [messageBarVM, selectedRows, standardProposalVM]);

    const handleApprove = useCallback(async () => {
        if(selectedRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        if(!selectedRows.every(entity => entity.status === "FINALIZED")){
            messageBarVM.showWarining("The selected proposal is not in \"FINALIZED\" state.");
            return;
        }
        standardProposalVM.onShowLoading();
        standardProposalVM.onApprove(selectedRows).then(async (data) => {       
            if(data === "success"){
                messageBarVM.showSuccess("Approve successful.");
                if(searchStatus !== ""){
                    await standardProposalVM.onGroupButtonClick(searchStatus);
                }else{
                    await standardProposalVM.searchStandardProposal(searchCriteria);
                }
                standardProposalVM.onHideLoading();
            }else{
                standardProposalVM.onHideLoading();
                messageBarVM.showError(data);
            } 
            standardProposalVM.onHideLoading();
        }).catch(error => {            
            standardProposalVM.onHideLoading();
        });
    }, [messageBarVM, searchCriteria, searchStatus, selectedRows, standardProposalVM]);


    const handleUnConfirm = useCallback(async () => {
        if(selectedRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        if(!selectedRows.every(entity => entity.status === "FINALIZED"  || entity.status === "PROPOSED")){
            messageBarVM.showWarining("The selected proposal is not in \"FINALIZED\" state.");
            return;
        }
        standardProposalVM.onShowLoading();
        standardProposalVM.onUnconfirm(selectedRows).then(async (data) => {       
            if(data === "success"){
                messageBarVM.showSuccess("Unconfirm successful.");
                if(searchStatus !== ""){
                    await standardProposalVM.onGroupButtonClick(searchStatus);
                }else{
                    await standardProposalVM.searchStandardProposal(searchCriteria);
                }
                standardProposalVM.onHideLoading();
            }else{
                standardProposalVM.onHideLoading();
                messageBarVM.showError(data);
            } 
            standardProposalVM.onHideLoading();
        }).catch(error => {            
            standardProposalVM.onHideLoading();
        });

    }, [messageBarVM, searchCriteria, searchStatus, selectedRows, standardProposalVM]);

    const handleRenew = useCallback(async () => {
        if(selectedRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        if(!selectedRows.every(entity => entity.status === "FINALIZED"  && entity.confirmedDate)){
            messageBarVM.showWarining("The selected proposal can not be renew.");
            return;
        }
        standardProposalVM.onShowLoading();
        standardProposalVM.onRenew(selectedRows.map(entity => Number(entity.key))).then(async (data) => {
            if(data === "success"){
                messageBarVM.showSuccess("Renew successful.");
                if(searchStatus !== ""){
                    await standardProposalVM.onGroupButtonClick(searchStatus);
                }else{
                    await standardProposalVM.searchStandardProposal(searchCriteria);
                }
                standardProposalVM.onHideLoading();
            }else{
                standardProposalVM.onHideLoading();
                messageBarVM.showError(data);
            } 
            standardProposalVM.onHideLoading();
        }).catch(error => {            
            standardProposalVM.onHideLoading();
        });

    }, [messageBarVM, searchCriteria, searchStatus, selectedRows, standardProposalVM]);

    const handleSubmit = useCallback(async () => {
        //isSTDProposalFinalizeApproved
        if(selectedRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        if(selectedRows.every(entity => entity.status === "FINALIZED")){
            messageBarVM.showWarining("Standard Proposal status is \"FINALIZED\", cannot be submit.");
            return;
        }
        standardProposalVM.onShowLoading();
        standardProposalVM.onSubmit(selectedRows).then(async (data) => {       
            if(data === "success"){
                messageBarVM.showSuccess("Submit successful.");
                if(searchStatus !== ""){
                    await standardProposalVM.onGroupButtonClick(searchStatus);
                }else{
                    await standardProposalVM.searchStandardProposal(searchCriteria);
                }
                standardProposalVM.onHideLoading();
            }else{
                standardProposalVM.onHideLoading();
                messageBarVM.showError(data);
            } 
            standardProposalVM.onHideLoading();
        }).catch(error => {            
            standardProposalVM.onHideLoading();
        });

    }, [messageBarVM, searchCriteria, searchStatus, selectedRows, standardProposalVM]);

    const handleDeleteClick = useCallback(async () => {
        if(!selectedRows.every(entity => entity.status === "NEW" || entity.status === "REJECTED")){
            messageBarVM.showWarining("Standard Proposal status is \"FINALIZED\", Cannot be deleted.");
            await standardProposalVM.searchStandardProposal(searchCriteria).then(data => {
                setIsLoading(false);
            });
            return;
        }
        setIsShowDelete(true);
        setDeletedProposal(selectedRows);
    }, [messageBarVM, searchCriteria, selectedRows, standardProposalVM]);

    const handleCancel = () => {
        // setIsLoading(true);
        setIsShowDelete(false);
        // standardProposalVM.searchStandardProposal(searchCriteria).then(data => {
        //     setIsLoading(false);
        // });
    }

    const handleDelete = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        if (!deletedProposal) return;

        standardProposalVM.onDeleteClick(deletedProposal).then((data) => {
            if (data && data === "success") {
                messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL)
                standardProposalVM.searchStandardProposal(searchCriteria).then(data => {
                    setIsLoading(false);
                });
            } else {
                messageBarVM.showError(data);
            }            
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        });
    }


    const isRenewDisable = () => {
        if(!isShowTariffItemTierPanel && !isShowCopyPanel && !isUpdateOrAdd && !isEditStdProposal && selectedRows.length > 0 && selectedRows.every(entity => entity.status === "FINALIZED" && entity.confirmedDate)) return false
        return true;
    }
    const isDeleteDisable = () => {
        if(!isShowTariffItemTierPanel && !isUpdateOrAdd && !isUpdateOrAdd && !isEditStdProposal && selectedRows.length > 0 && selectedRows.every(entity => entity.status === "NEW" || entity.status === "REJECTED")) return false
        return true;
    }
    const isSubmitDisable = () => {
        if(!isShowTariffItemTierPanel && !isUpdateOrAdd && !isUpdateOrAdd && !isEditStdProposal && selectedRows.length > 0 && selectedRows.every(entity => entity.status === "NEW" || entity.status === "REJECTED" || entity.status === "PROPOSED")) return false
        return true;
    }
    const isUnconfrimDisable = () => {
        if(!isShowTariffItemTierPanel && !isShowCopyPanel && !isUpdateOrAdd && !isEditStdProposal && selectedRows.length > 0 && selectedRows.every(entity => entity.status === "FINALIZED")) return false
        return true;
    }
    const isApproveDisable = () => {
        if(!isShowTariffItemTierPanel && !isUpdateOrAdd && !isEditStdProposal && selectedRows.length > 0 && selectedRows.every(entity => (entity.status === "FINALIZED") && !entity.confirmedDate)) return false
        return true;
    }

    const isRejectDisable = () => {
        if(!isShowTariffItemTierPanel && !isUpdateOrAdd && !isEditStdProposal && selectedRows.length > 0 && selectedRows.every(entity => (entity.status === "FINALIZED") && !entity.confirmedDate)) return false
        return true;
    }

    const isSearchDisable = () => {
        if(!isShowTariffItemTierPanel && !isEditStdProposal) return false
        return true;
    }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{STANDARD_PROPOSAL_CONSTANT.STANDARD_PROPOSAL}</HeaderTitle>
        {(standardProposalState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={isSearchDisable()} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            <IconButton fileName='Icon-add' disabled={isShowTariffItemTierPanel||isShowCopyPanel||isUpdateOrAdd||isEditStdProposal} size='medium' toolTipText={'Add'} onClick={handleAdd}/>
            <div className="add-seperator"/>
            {<HPHButton disabled={isRenewDisable()} label={STANDARD_PROPOSAL_CONSTANT.RENEW_BUT} size={'Small'} theme={'Secondary'} onClick={handleRenew} />}
            {<HPHButton disabled={isDeleteDisable()} label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} />}
            <div className="add-seperator"/>
            {<HPHButton disabled={isUnconfrimDisable()} label={WorkspaceConstant.Common.BUTTON_UNCONFIRM} size={'Small'} theme={'Secondary'} onClick={handleUnConfirm} />}
            {<HPHButton disabled={isSubmitDisable()} label={STANDARD_PROPOSAL_CONSTANT.SUBMIT_BUT} size={'Small'} theme={allowUpdate?'Secondary':'Primary'} onClick={handleSubmit} />}
            {(allowUpdate) && <><div className="add-seperator"/>
            <HPHButton disabled={isRejectDisable()} label={STANDARD_PROPOSAL_CONSTANT.REJECT_BUT} size={'Small'} theme={'Secondary'} onClick={handleReject} />
            <HPHButton disabled={isApproveDisable()} label={STANDARD_PROPOSAL_CONSTANT.APPROVE_BUT} size={'Small'} theme={'Primary'} onClick={handleApprove} /></>}
        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${StandardProposalConstant.Title.STANDARD_PROPOSAL}`} 
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
        </StyledAction>
    </Sidebarheader>
}

export default memo(StandardProposalTitleBar);