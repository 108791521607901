import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_IS_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalTariffItemISColumnDefinition";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const TariffItemISTablePanel: React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const {currentSelectedRow, currentTariffItem,currentTariffItemIs,tariffItemIsSelectedRows,isEditIs,isAddIs,isEditTier,isAddTier } = standardProposalState;
    let gridRef: any = useRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalVM.updateSelectedTariffItemIsRows(selectedRows);
    }, [standardProposalVM])


    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice());
        
        // if (!standardProposalState.tariffItemSelectedRows ||
        //     standardProposalState.tariffItemSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })

    const handleAdd = useCallback(() => {
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showWarining("Standard Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot Add.");
            return;
        }
        standardProposalVM.onAddIs();
    }, [currentSelectedRow.status, messageBarVM, standardProposalVM]);



    const handEdit = useCallback(() => {
        if("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status){
            messageBarVM.showWarining("Standard Proposal status is \"PROPOSAL\" or \"FINALIZED\", cannot be edited.");
            return;
        }
        if(currentTariffItemIs?.shiftCode == null){
            messageBarVM.showWarining("Please select a IS record.");
            return;
        }
        standardProposalVM.onTariffItemISEidtClick();
    }, [currentSelectedRow.status, currentTariffItemIs?.shiftCode, messageBarVM, standardProposalVM]);

    const handDelete = useCallback(() => {
        if(tariffItemIsSelectedRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        standardProposalVM.onTariffItemIsDeleteClick(tariffItemIsSelectedRows);
    }, [messageBarVM, standardProposalVM, tariffItemIsSelectedRows]);

    const memoBtns = useMemo(()=>{

        const hdrBtns = [];
        if(!(isAddTier||isEditTier||isAddIs||isEditIs)){
            hdrBtns.push({                
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
            hdrBtns.push({                
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }
        return hdrBtns
    },[isAddIs, isAddTier, isEditIs, isEditTier]) 

    const memoStandardProposalTariffItemTierTable = useMemo(() => {

        return (
            <HPHTable
                id='standard-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice()}
                data={transferRowData(currentTariffItem.stdIsList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handEdit}
                onDeleteButton={handDelete}
                showPaginator={false}
                editable={false}
                showAddIcon={!(isAddTier||isEditTier||isAddIs||isEditIs)}
                showUploadIcon={false}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 780px)"
                ref={gridRef}
            />
        );
    }, [currentTariffItem.stdIsList, handDelete, handEdit, handleAdd, handleSelectionChange, isAddIs, isAddTier, isEditIs, isEditTier, memoBtns])

    return <>
    {/* <div className="main-comp-wrapper"> */}
        <TableWrapper>
        {memoStandardProposalTariffItemTierTable}
    </TableWrapper>
    {/* </div> */}
    </>;
}

export default memo(TariffItemISTablePanel);
