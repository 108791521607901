import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalDetailColumnDefinition";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalDetailTracked } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const StandardProposalDetailTablePanel: React.FC = () => {
    const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const { currentStandardProposal,currentSelectItemRows } = standardProposalDtlState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    
    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF.slice());
        
        // if (!standardProposalState.tariffItemSelectedRows ||
        //     standardProposalState.tariffItemSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalDtlVM.updateSelectedRows(selectedRows);
    }, [standardProposalDtlVM])

    const handleAdd = useCallback(() => {
        if(!currentStandardProposal.companyCode){
            messageBarVM.showWarining("Company Code is mandatory.");
            return;
        }
        if(!currentStandardProposal.effectiveDate){
            messageBarVM.showWarining("Effective Date is mandatory.");
            return;
        }
        if(!currentStandardProposal.expiryDate){
            messageBarVM.showWarining("Expiry Date is mandatory.");
            return;
        }
        if(!currentStandardProposal.currencyCode){
            messageBarVM.showWarining("Currency is mandatory.");
            return;
        }
        if(!currentStandardProposal.proposalCreatedByMethod){
            messageBarVM.showWarining("Proposal By is mandatory.");
            return;
        }
        if(currentStandardProposal.tariffNatureList?.length === 0){
            messageBarVM.showWarining("Tariff Nature is mandatory.");
            return;
        }
        standardProposalDtlVM.onAddTariffItemClick();
    }, [currentStandardProposal.companyCode, currentStandardProposal.currencyCode, currentStandardProposal.effectiveDate, currentStandardProposal.expiryDate, currentStandardProposal.proposalCreatedByMethod, currentStandardProposal.tariffNatureList, messageBarVM, standardProposalDtlVM]);

    const handDelete = useCallback(() => {
        if(currentSelectItemRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        standardProposalDtlVM.onTariffItemDeleteClick(currentSelectItemRows);
    }, [messageBarVM, standardProposalDtlVM, currentSelectItemRows]);

    const memoStandardProposalTable = useMemo(() => {

        return (
            <HPHTable
                id='standard-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_STANDARD_PROPOSAL_DETAIL_COL_DEF.slice()}
                data={transferRowData(standardProposalDtlState.standardProposalItemList?.slice() ?? [])}
                headerActionButtons={[
                    {
                        id: 'onDeleteButton',
                        icon: 'Icon-trash',
                        title: 'Delete'
                    },
                ]}
                onDeleteButton={handDelete}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                ref={gridRef}
            />
        );
    }, [handDelete, handleAdd, handleSelectionChange, standardProposalDtlState.standardProposalItemList])
    return <><TableWrapper>
        {memoStandardProposalTable}
    </TableWrapper>
    </>;
}

export default memo(StandardProposalDetailTablePanel);
