import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_IS_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalTariffItemISColumnDefinition";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalDetailTracked } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const AddStdTariffItemIsTablePanel: React.FC = () => {
    const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const {currentSelectItem,currentTariffItemIs,tariffItemIsSelectedRows } = standardProposalDtlState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice());
        
        // if (!standardProposalState.tariffItemSelectedRows ||
        //     standardProposalState.tariffItemSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalDtlVM.updateSelectedTariffItemIsRows(selectedRows);
    }, [standardProposalDtlVM])



    const handleAdd = useCallback(() => {
        standardProposalDtlVM.onAddIs();
    }, [standardProposalDtlVM]);



    const handEdit = useCallback(() => {
        if(currentTariffItemIs?.shiftCode == null){
            messageBarVM.showWarining("Please select a Tier record.");
            return;
        }
        standardProposalDtlVM.onTariffItemISEidtClick();
    }, [currentTariffItemIs?.shiftCode, messageBarVM, standardProposalDtlVM]);

    const handDelete = useCallback(() => {
        if(tariffItemIsSelectedRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        standardProposalDtlVM.onTariffItemIsDeleteClick(tariffItemIsSelectedRows);
    }, [messageBarVM, standardProposalDtlVM, tariffItemIsSelectedRows]);

    const memoStandardProposalTariffItemTierTable = useMemo(() => {

        return (
            <HPHTable
                id='standard-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice()}
                data={transferRowData(currentSelectItem?.stdIsList ?? [])}
                headerActionButtons={[
                    {
                        id: 'onEditButton',
                        icon: 'Icon-pen',
                        title: 'Edit'
                    },
                    {
                        id: 'onDeleteButton',
                        icon: 'Icon-trash',
                        title: 'Delete'
                    },
                ]}
                onEditButton={handEdit}
                onDeleteButton={handDelete}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                showUploadIcon={false}
                rowSelection={"single"}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 780px)"
                ref={gridRef}
            />
        );
    }, [currentSelectItem?.stdIsList, handDelete, handEdit, handleAdd, handleSelectionChange])

    return <>
    {/* <div className="main-comp-wrapper"> */}
        <TableWrapper>
        {memoStandardProposalTariffItemTierTable}
    </TableWrapper>
    {/* </div> */}
    </>;
}

export default memo(AddStdTariffItemIsTablePanel);
