export interface StdProposalIsEntity {

    id?: number | null,
	proposalId?: number | null,
	proposalItemId?: number | null,
	shiftCode?: string | null,
	percentage?: number | null,

    [key: string]: string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_STD_PROPOSAL_IS_ENTITY: StdProposalIsEntity = {

    id: null,
	proposalId: null,
	proposalItemId: null,
	shiftCode: null,
	percentage: null,
}