import { StandardProposalConstant, standardProposalTariffItemRequiredFieldList } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useStandardProposalDetailTracked } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useEffect, useMemo } from "react";
import { CommonField, FieldType, IFieldValue } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const AddTariffItemHeaderBar:React.FC = () => {
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const { initTariffCode,currentSelectItem,currentStandardProposal } = standardProposalDtlState;
    const standardProposalDtlVM = useStandardProposalDetailVM();

    useEffect(() => {
        const refreshTariffCode = async()=>{
            console.log("refreshTariffCode");
            standardProposalDtlVM.initComboBoxTariffCode(currentStandardProposal,currentSelectItem.tariffType??"");
        }    
        if(currentSelectItem.tariffType){
            initTariffCode && refreshTariffCode();
        }
            
    },[initTariffCode,currentSelectItem, currentStandardProposal, standardProposalDtlVM])


    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"5px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentSelectItem?.tariffType || ''}
                fieldValue={currentSelectItem?.tariffType}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                maxLength={60}
                options={standardProposalDtlState.dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={standardProposalTariffItemRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalDtlVM.onTariffItemFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.TARIFF_TYPE, currentSelectItem?.tariffType, standardProposalDtlState.dynamicOptions.tariffTypeDropdownOptions, standardProposalDtlVM])

    const memotariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"5px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentSelectItem?.tariffCode || ''}
                fieldValue={currentSelectItem?.tariffCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.TARIFF_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                maxLength={60}
                options={standardProposalDtlState.dynamicOptions.tariffCodeDropdownOptions}
                requiredFieldList={standardProposalTariffItemRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalDtlVM.onTariffItemFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.TARIFF_CODE, currentSelectItem?.tariffCode, standardProposalDtlState.dynamicOptions.tariffCodeDropdownOptions, standardProposalDtlVM])


    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom: "5px",marginLeft: "2px"}}>
            <CommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentSelectItem?.forwarderCode || ''}
                fieldValue={currentSelectItem?.forwarderCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.FORWARDER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'forwarderCode'}
                maxLength={60}
                options={standardProposalDtlState.dynamicOptions.forwarderCodeDropdownOptions}
                requiredFieldList={standardProposalTariffItemRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    standardProposalDtlVM.onTariffItemFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.FORWARDER_CODE, currentSelectItem?.forwarderCode, standardProposalDtlState.dynamicOptions.forwarderCodeDropdownOptions, standardProposalDtlVM])



    return <Sidebarheader style={{width: '100%'}}>
        <CriteriaItemContainer>
            {memoTariffType}
            {memotariffCode}
            {memoForwarderCode}
        </CriteriaItemContainer>
    </Sidebarheader>
}

export default memo(AddTariffItemHeaderBar);